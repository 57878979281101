import hexRgb from 'hex-rgb';

function deriveOrdinalSuffix(n) {
  return ['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th';
}

function deriveMonthNameFromIndex(index) {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  return monthNames[index];
}

export function deriveSlugFromString(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

export function deriveDatetimeFromString(datetimeString) {
  const datetime = new Date(datetimeString);
  const day = datetime.getDate();
  const suffix = deriveOrdinalSuffix(day);
  const month = deriveMonthNameFromIndex(datetime.getMonth());
  const year = datetime.getFullYear();

  return `${day}${suffix} ${month} ${year}`;
}

export function deriveRgbaFromHex(hex, alpha) {
  const rgb = hexRgb(hex, { format: 'array' }).slice(0, -1).join(',');

  return `rgba(${rgb},${alpha})`;
}

import React, { useEffect, useState } from 'react';
import useScrollPosition from 'use-scroll-position';
import { ScrollButton } from './styled';
import ArrowTop from '../../images/iconArrowTop.svg';

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);
  const scrollPosition = useScrollPosition();

  useEffect(() => {
    if (scrollPosition > 500) {
      if (!isVisible) {
        setIsVisible(true);
      }
    } else {
      if (isVisible) {
        setIsVisible(false);
      }
    }
  }, [scrollPosition, isVisible]);

  return (
    <ScrollButton isVisible={isVisible} onClick={() => typeof window != 'undefined' && window.scrollTo(0, 0)}>
      <span className="screen-reader-text">Scroll to top</span>
      <ArrowTop />
    </ScrollButton>
  );
}

export default ScrollToTop;

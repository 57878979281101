import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

const MetaTags = ({
  url,
  metaTitle,
  metaDescription
}) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
              description
              keywords
            }
          }
        }
      `}
      render={({ site: { siteMetadata } }) => {
        const title = metaTitle ? `${metaTitle} | ${siteMetadata.title}` : siteMetadata.title;
        const description = metaDescription ? metaDescription : siteMetadata.description;
        const thumbnailUrl = 'https://assortment.io/images/assortment-social-thumbnail.png';
        return (
          <Helmet>
            <title>{title}</title>
            <html lang="en" />
            <meta name="description" content={description} />
            <meta name="keywords" content={siteMetadata.keywords} />

            {url && <link rel="canonical" href={url} />}

            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@assortmentio" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={thumbnailUrl} />

            {url && <meta property="og:url" content={url} />}
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={thumbnailUrl} />
            <meta property="og:image:width" content="202" />
            <meta property="og:image:height" content="202" />
          </Helmet>
        );
      }}
    />
  );
};

export default MetaTags;

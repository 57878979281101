import { css } from '@emotion/react';
import {
  baseColors,
  breakpoints,
  fonts
} from './constants';
import { deriveRgbaFromHex } from './utils';

const globalStyles = css`
/**************************************
 ** Core styling
 **************************************/

* {
  box-sizing: inherit;
}

html,
body {
  margin: 0;
  padding: 0;
}

html {
  font-family: ${fonts.base};
  font-size: 112.5%;
  font-weight: 400;
  line-height: 1.4;
  overflow-y: scroll;
  box-sizing: border-box;

  @media screen and (min-width: ${breakpoints.bp3}) {
    font-size: 125%;
  }
}

body {
  color: ${baseColors.text};
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: .25rem;
  font-weight: 700;
  line-height: 1.1;
  color: ${baseColors.black};
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

a {
  text-decoration: none;
  color: ${baseColors.black};
  text-decoration: underline;
  text-decoration-color: ${deriveRgbaFromHex(baseColors.black, .15)};
  transition: text-decoration-color .15s ease-in-out;

  &:hover, &:focus {
    text-decoration-color: ${deriveRgbaFromHex(baseColors.black, .4)};
  }
}

button,
input[type="submit"] {
  font-family: ${fonts.base};
  padding: 0;
  margin: 0;
  background: 0;
  border: 0;
  cursor: pointer;
}

figure p {
  margin: 0;
  padding: 1rem;
  line-height: 0;
}

/**************************************
 ** Code highlighting
 **************************************/

code,
pre {
  margin: 0;
  font-family: ${fonts.code};
  white-space: pre;
  -moz-tab-size: 2;
  -o-tab-size: 2;
  tab-size: 2;
}

:not(pre) > code {
  padding: .15rem;
  white-space: nowrap;
  font-size: .85rem;
  color: black;
  background: #FFFAE0;
  border-radius: 0.25rem;
}

/**************************************
 ** PrismJS & Gatsby highlighting
 **************************************/

.gatsby-highlight-code-line {
  display: block;
  margin: 0 -1rem;
  padding-right: 1rem;
  padding-left: .75rem;
  border-left: .25rem solid #ffa7c4;
  background-color: hsla(207, 95%, 15%, 1);
}

.gatsby-highlight {
  margin: 0 0 2rem 0;
  font-size: .7rem;
  color: ${baseColors.white};
  background: #011627;
  border-radius: .5rem;
  -webkit-overflow-scrolling: touch;

  pre[class*='language-'] {
    overflow: auto;
    padding: 1rem;
  }

  .token.attr-name {
    color: rgb(173, 219, 103);
    font-style: italic;
  }

  .token.comment {
    font-style: italic;
    color: rgb(99, 119, 119);
  }

  .token.string,
  .token.url {
    color: rgb(173, 219, 103);
  }

  .token.variable {
    color: rgb(214, 222, 235);
  }

  .token.number {
    color: rgb(247, 140, 108);
  }

  .token.builtin,
  .token.char,
  .token.constant,
  .token.function {
    color: rgb(130, 170, 255);
  }

  .token.punctuation {
    color: rgb(199, 146, 234);
  }

  .token.selector,
  .token.doctype {
    color: rgb(199, 146, 234);
  }

  .token.class-name {
    color: rgb(255, 203, 139);
  }

  .token.tag,
  .token.operator,
  .token.keyword {
    color: #ffa7c4;
  }

  .token.boolean {
    color: rgb(255, 88, 116);
  }

  .token.property {
    color: rgb(128, 203, 196);
  }

  .token.namespace {
    color: rgb(178, 204, 214);
  }
}

/**************************************
 ** Utilities
 **************************************/

.screen-reader-text {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}
`;

export default globalStyles;

import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { breakpoints } from '../../constants';

const Wrapper = styled.footer`
  margin-top: 2rem;
  padding: 1rem 0;
  font-size: .7rem;
  text-align: center;
`;

const Row = styled.div`
  @media screen and (min-width: ${breakpoints.bp2}) {
    display: flex;
    justify-content: center;
  }
`;

const Column = styled.div`
  @media screen and (min-width: ${breakpoints.bp2}) {
    margin: 0 .1rem;
  }
`;

const Footer = () => (
  <Wrapper>
    <Row>
      <Column><Link to="/about">About</Link> - <Link to="/faq">FAQ</Link> - <Link to="/contact">Contact</Link> - <a href="http://feeds.feedburner.com/Assortmentio">RSS feed</a>.</Column>
      <Column>Follow us on <a href="https://twitter.com/assortmentio">Twitter</a>, <a href="https://www.facebook.com/assortmentio">Facebook</a> or <a href="https://github.com/assortment">Github</a>.</Column>
    </Row>
    <Row>
      <Column>&copy; Copyright {(new Date()).getFullYear()} <Link to="/">Assortment</Link>.</Column>
      <Column>Created and maintained by <a href="http://twitter.com/_lukewh">Luke Whitehouse</a>.</Column>
    </Row>
  </Wrapper>
);

export default Footer;

import styled from '@emotion/styled';
import { baseColors, breakpoints } from '../../constants';

export const Form = styled.form`
  position: relative;
  display: ${props => props.isVisible ? 'block' : 'none'};
  width: 100%;
  padding-top: 1rem;

  @media screen and (min-width: ${breakpoints.bp2}) {
    display: flex;
    width: auto;
    padding-top: 0;
  }
`;

export const Input = styled.input`
  appearance: none;
  width: 100%;
  height: 2rem;
  padding: 0 1rem;
  font-size: .85rem;
  border: 1px solid ${baseColors.grey.base};
  border-radius: 1rem;
  transition: border-color .15s ease-out;

  @media screen and (min-width: ${breakpoints.bp2}) {
    width: 175px;
    height: 1.8rem;
    font-size: .65rem;
  }

  &:focus {
    outline: 0;
    border-color: #BBBBBB;
  }

  &::-webkit-search-cancel-button {
    appearance: none;
  }

  &::-ms-clear {
    appearance: none;
  }
`;

export const Button = styled.button`
  position: absolute;
  right: .25rem;
  height: 2rem;
  width: 2rem;
  line-height: 0;

  @media screen and (min-width: ${breakpoints.bp2}) {
    height: 1.8rem;
    width: 1.8rem;
  }
`;

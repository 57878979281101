import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import Nav from '../Nav';
import Logo from '../../images/assortment-logo.svg';
import { breakpoints } from '../../constants';

const Wrapper = styled.header`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding-top: 1rem;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-grow: 1;
`;

const LogoLink = styled(Link)`
  line-height: 0;
  border-bottom: 0;
`;

const LogoImage = styled(Logo)`
  @media screen and (max-width: ${breakpoints.bp2}) {
    width: 150px;
    height: 37px;
  }
`;

const Header = () => (
  <Wrapper>
    <LogoWrapper>
      <LogoLink to="/" data-testid="logo-link">
        <LogoImage data-testid="logo" />
      </LogoLink>
    </LogoWrapper>
    <Nav />
  </Wrapper>
);

export default Header;

import React, { useState } from 'react';
import { Location } from '@reach/router';
import {
  NavWrapper,
  NavList,
  NavItem,
  NavLink,
  Toggle
} from './styled';
import SearchBar from '../SearchBar';
import IconMenu from '../../images/iconMenu.svg';
import IconClose from '../../images/iconClose.svg';
import IconSearch from '../../images/iconSearch.svg';

const Nav = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const onNavOpen = () => { setIsNavOpen(!isNavOpen); setIsSearchOpen(false); };
  const onSearchOpen = () => { setIsSearchOpen(!isSearchOpen); setIsNavOpen(false); };

  return (
    <Location>
      {({ location }) => (
        <React.Fragment>
          <Toggle
            onClick={onNavOpen}
            isActive={isNavOpen}
            aria-pressed={isNavOpen}
            aria-expanded={isNavOpen}
            aria-controls="nav"
            data-testid="toggle-main-nav"
          >
            <span className="screen-reader-text">Menu</span>
            {isNavOpen ? <IconClose /> : <IconMenu />}
          </Toggle>
          {location.pathname !== '/search' && 
            <Toggle
              onClick={onSearchOpen}
              isActive={isSearchOpen}
              aria-pressed={isSearchOpen}
              aria-expanded={isSearchOpen}
              aria-controls="search-bar"
              data-testid="toggle-search-bar"
            >
              <span className="screen-reader-text">Search</span>
              {isSearchOpen ? <IconClose /> : <IconSearch />}
            </Toggle>
          }
          <NavWrapper isVisible={isNavOpen} id="nav" data-testid="main-nav">
            <NavList>
              <NavItem><NavLink to="/">Home</NavLink></NavItem>
              <NavItem><NavLink to="/categories/tutorial">Tutorials</NavLink></NavItem>
              <NavItem><NavLink to="/categories/article">Articles</NavLink></NavItem>
              <NavItem><NavLink to="/tags">Tags</NavLink></NavItem>
            </NavList>
          </NavWrapper>
          {location.pathname !== '/search' && <SearchBar isVisible={isSearchOpen} />}
        </React.Fragment>
      )}
    </Location>
  );
};

export default Nav;

import styled from '@emotion/styled';
import { breakpoints } from '../../constants';

const BreakpointIndicator = styled.div`
  &:before {
    content: "bp0";
    position: fixed;
    z-index: 99999;
    left: 1rem;
    bottom: 1rem;
    display: inline-block;
    padding: .5rem;
    font-size: .8rem;
    text-transform: uppercase;
    color: #FFF;
    background-color: rgba(0, 0, 0, .75);

    @media screen and (min-width: ${breakpoints.bp1}) {
      content: "bp1";
    }
    
    @media screen and (min-width: ${breakpoints.bp2}) {
      content: "bp2";
    }
    
    @media screen and (min-width: ${breakpoints.bp3}) {
      content: "bp3";
    }
    
    @media screen and (min-width: ${breakpoints.bp4}) {
      content: "bp4";
    }
    
    @media screen and (min-width: ${breakpoints.bp5}) {
      content: "bp5";
    }
    
    @media screen and (min-width: ${breakpoints.bp6}) {
      content: "bp6";
    }
  }
`;

export default BreakpointIndicator;

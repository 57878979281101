import styled from '@emotion/styled';
import { baseColors } from '../../constants';

export const ScrollButton = styled.button`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  padding: .5rem;
  line-height: 0;
  background-image: linear-gradient( to top, #FFFFFF 0%, ${baseColors.grey.base} 90% );
  border-radius: 100%;
  transition: all .15s ease-in-out;
  opacity: ${props => props.isVisible ? 1 : 0};
  transform: scale(${props => props.isVisible ? 1 : 0});

  &:hover,
  &:focus {
    outline: none;
    background-image: linear-gradient( to top, #FFFFFF 0%, #DDDDDD 90% );
  }
`;

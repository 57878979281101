import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { baseColors } from '../../constants';
import { deriveRgbaFromHex } from '../../utils';

export const Wrapper = styled.article`
  position: relative;
  padding: 3.75rem 2rem;
  background-color: ${props => props.theme.light};
  background-image: linear-gradient(
    to bottom,
    ${props => deriveRgbaFromHex(props.theme.light,1)} 0%,
    rgba(255,255,255,1) 100%
  );
  border-radius: 1rem 1rem 0 0;
`;

export const MetaData = styled.div`
  position: absolute;
  top: 1.25rem;
  left: 0;
  font-size: .7rem;
`;

export const Category = styled(Link)`
  position: relative;
  display: inline-block;
  margin-right: .5rem;
  padding: .2rem .75rem;
  color: ${baseColors.black};
  text-decoration: none;
  background-color: ${props => props.theme.base};
  background-image: linear-gradient(
    to right,
    ${props => deriveRgbaFromHex(props.theme.light,1)} 0%,
    ${props => deriveRgbaFromHex(props.theme.base,1)} 40%
  );
  border-radius: 0 .5rem .5rem 0;
  transition: all .15s ease-out;

  &:hover {
    background-color: ${props => props.theme.dark};
    background-image: linear-gradient(
      to right,
      ${props => deriveRgbaFromHex(props.theme.light,1)} 0%,
      ${props => deriveRgbaFromHex(props.theme.dark,1)} 40%
    );
  }
`;

export const Excerpt = styled.p`
  margin-bottom: 0;
  color: ${baseColors.grey.dark};
`;

export const PostLink = styled(Link)`
  text-decoration: none;

  &:hover {
    color: ${baseColors.link.dark};
  }
`;

export const Tags = styled.div`
  position: absolute;
  bottom: 1.5rem;
  font-size: .7rem;
`;

export const Tag = styled(Link)`
  margin-right: .25rem;
  padding: .15rem .5rem;
  text-decoration: none;
  background-color: ${baseColors.white};
  border: 1px solid ${props => deriveRgbaFromHex(props.theme.base, .5)};
  border-radius: 1rem;

  &:hover {
    border-color: ${props => props.theme.dark};
  }
`;

import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Global } from '@emotion/react';
import styled from '@emotion/styled';
import BreakpointIndicator from '../BreakpointIndicator';
import Header from '../Header';
import Footer from '../Footer';
import ScrollToTop from '../ScrollToTop';
import globalStyles from '../../globalStyles';

const PageContainer = styled.div`
  width: 90%;
  max-width: 1500px;
  margin: 0 auto;
`;

const PageLayout = ({
  children
}) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={({ site: { siteMetadata: { title } } }) => (
      <React.Fragment>
        <Global styles={globalStyles} />
        {process.env.NODE_ENV !== 'production' && <BreakpointIndicator />}
        <PageContainer>
          <Header siteTitle={title} />
          <main>{children}</main>
          <Footer />
        </PageContainer>
        <ScrollToTop />
      </React.Fragment>
    )}
  />
);

export default PageLayout;

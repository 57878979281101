export const baseColors = {
  black: '#000000',
  white: '#FFFFFF',
  grey: {
    base: '#E8E8E8',
    dark: '#555555',
    light: '#F7F7F7'
  },
  link: {
    base: '#4285f4',
    dark: '#1266f1',
    light: '#F9FBFF'
  }
};

export const brandColors = {
  tutorial: {
    base: '#F8D892',
    dark: '#F5C762',
    light: '#FEFBF4'
  },
  article: {
    base: '#AFDEB5',
    dark: '#8BCF94',
    light: '#F7FBF7'
  },
  'external-link': {
    base: '#EFB5B5',
    dark: '#E68B8B',
    light: '#FDF7F7'
  },
  snippet: {
    base: '#AED7EA',
    dark: '#86C3E0',
    light: '#F6FBFC'
  }
};

export const stateColors = {
  success: '#AFDEB5',
  warning: '#F8D892',
  error: '#EFB5B5',
  info: '#AED7EA'
};

export const fonts = {
  base: '"Nunito", "Open Sans", Helvetica, Arial, Verdana, "Verdana Ref", sans-serif',
  code: 'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace'
};

export const breakpoints = {
  bp1: '30em',
  bp2: '48em',
  bp3: '62em',
  bp4: '78em',
  bp5: '87em',
  bp6: '100em'
};

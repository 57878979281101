import React from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { baseColors, breakpoints } from '../../constants';
import { deriveRgbaFromHex } from '../../utils';

export const NavWrapper = styled.nav`
  display: ${props => props.isVisible ? 'block' : 'none'};
  width: 100%;
  padding-top: 1rem;
  list-style: none;

  @media screen and (min-width: ${breakpoints.bp2}) {
    display: flex;
    width: auto;
    margin-right: .75rem;
    padding-top: 0;
  }
`;

export const NavList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  @media screen and (min-width: ${breakpoints.bp2}) {
    display: flex;
  }
`;

export const NavItem = styled.li`
  border-bottom: 1px solid ${baseColors.grey.base};

  @media screen and (min-width: ${breakpoints.bp2}) {
    padding: 0 .75rem;
    border-bottom: 0;
  }

  &:first-of-type {
    border-top: 1px solid ${baseColors.grey.base};

    @media screen and (min-width: ${breakpoints.bp2}) {
      border-top: 0;
    }
  }
`;

export const NavLink = ({ children, ...props }) => {
  const linkStyles = css`
    display: block;
    padding: 1rem;
    font-size: .7rem;
    text-align: center;
    text-decoration: none;

    @media screen and (min-width: ${breakpoints.bp2}) {
      display: inline-block;
      padding: 0;
      text-align: left;
      text-decoration: underline;
      text-decoration-color: ${deriveRgbaFromHex(baseColors.black, .15)};

      &:hover, &:focus {
        text-decoration-color: ${deriveRgbaFromHex(baseColors.black, .4)};
      }
    }
  `;

  return props.to
    ? <Link {...props} css={linkStyles}>{children}</Link>
    : <a {...props} css={linkStyles}>{children}</a>
}

export const Toggle = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  margin-left: .5rem;
  border: 1px solid ${baseColors.grey.base};

  &:focus {
    outline: 0;
    box-shadow: 0 0 5px ${baseColors.grey.dark};
  }

  ${props => props.isActive && `
    background-color: ${baseColors.grey.light};
    border-color: #CCCCCC;
  `}

  @media screen and (min-width: ${breakpoints.bp2}) {
    display: none;
  }
`;

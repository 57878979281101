import React, { useState } from 'react';
import { navigate } from 'gatsby';
import {
  Form,
  Input,
  Button
} from './styled';
import IconSearch from '../../images/iconSearch.svg';

const SearchBar = ({ isVisible }) => {
  const [query, setQuery] = useState('');

  const onSubmit = event => {
    event.preventDefault();
    navigate(`/search?q=${query}`);
  };

  return (
    <Form
      onSubmit={onSubmit}
      isVisible={isVisible}
      id="search-bar"
      data-testid="header-search-bar"
    >
      <Input
        aria-label="Search posts on Assortment"
        type="search"
        id="search-bar-input"
        placeholder="Search posts"
        onChange={(e) => setQuery(e.target.value)}
        data-testid="header-search-bar-input"
      />
      <Button type="submit" data-testid="header-search-bar-button">
        <span className="screen-reader-text">Search</span>
        <IconSearch />
      </Button>
    </Form>
  );
}

export default SearchBar;
